.Navbar {
    background-color: #FFFFFF;
    color: white;
    padding: 10px 40px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  
    .nav-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
  
      .logo-container {
        .img-box {
          width: 150px;
          height: 100%;
          img {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }
      
      .left-part{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        .btn-admin{
        width: fit-content;
        padding: 6px 16px;
        border: none;
        background-color: #00A9FF;
        color: white;
        font-size: 0.5rem;
		    font-weight: bold;
        border-radius: 2px;
        cursor: pointer;

        &:hover{
            background-color: #00aaffcd;
        }
        &:active{
            background-color: #00A9FF;
        }
        }
      }
    }
  }
  