.Header{
    .header-container{
        .header {
            background-color: #FFFFFF;
            color: white;
            padding: 10px 40px;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1000;
          
            .nav-container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
          
              .logo-container {
                .img-box {
                  width: 150px;
                  height: 100%;
                  img {
                    width: 100%;
                    height: auto;
                    display: block;
                  }
                }
              }
              
              .left-part{
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
        
                .btn-close{
                width: fit-content;
                padding: 6px 16px;
                border: none;
                background-color: rgba(255, 0, 0, 0.689);
                color: white;
                font-size: 0.5rem;
                    font-weight: bold;
                border-radius: 2px;
                cursor: pointer;
        
                &:hover{
                    background-color: red;
                }
                &:active{
                    background-color: rgba(255, 0, 0, 0.689);
                }
                }
              }
            }
          }
    }
}