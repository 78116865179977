.SendEmail {
    background-color: #F1F1F1;
    display: flex;
    justify-content: center;
    width: 100vw;
    height: calc(100vh - 60px);
    margin-top: 60px;

    .email-container {
        width: 400px;
        height: 300px;
        padding: 20px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-top: 5%;

        display: flex;
        /* Make it a flex container */
        flex-direction: column;
        /* Stack children vertically */
        align-items: center;
        /* Align items at the center horizontally */
        /* justify-content: space-between; */
        /* Add space between items */

        h1 {
            margin-bottom: 30px;
        }

        h4 {
            margin-bottom: 10px;
            /* Adjust the margin as needed */
        }

        .email-form {
            width: 100%;
            padding-inline: 20px;

            .form-group {
                position: relative;
                margin-block: 20px;
                /* Adjust the margin as needed */

                label {
                    position: absolute;
                    font-size: 0.8rem;
                    color: gray;
                    top: 0;
                    left: 0;
                    margin: 5px;
                    padding: 0 5px;
                    background-color: #fff;
                }

                .email-input {
                    width: 100%;
                    padding: 16px;
                    font-size: 16px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    box-sizing: border-box;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    /* Adjust the margin as needed */
                }

                .error-container {
                    display: flex;

                    .error-message {
                        color: red;
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .Email {
        padding-top: 30%;
    }
}