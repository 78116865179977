.Admin {
  width: 100vw;
  background-color: #f1f1f1;
  margin-top: 50px;

  .header {
    background-color: #ffffff;
    color: white;
    padding: 10px 40px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;

    .nav-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .logo-container {
        .img-box {
          width: 150px;
          height: 100%;

          .horizontal-logo {
            width: 100%;
            height: auto;
            display: block;
          }
          .square-logo {
            display: none;
          }
        }
      }

      .left-part {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn-logout {
          width: fit-content;
          padding: 6px 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          cursor: pointer;

          .btn-logo,
          .btn-text {
            color: rgba(255, 0, 0, 0.689);
            font-size: 0.7rem;
          }

          &:hover {
            .btn-logo,
            .btn-text {
              color: red;
            }
          }

          &:active {
            .btn-logo,
            .btn-text {
              color: rgba(255, 0, 0, 0.689);
            }
          }
        }
      }
    }
  }

  .admin-login-section {
    width: 100vw;

    .main-heading {
      text-align: center;
      font-size: 1.5rem;
      padding-top: 10px;
      margin-bottom: 1.25rem;
    }

    .email-table {
      width: 80%;
      margin: 0 auto;
      border-collapse: collapse;

      .email-row:hover {
        background-color: whitesmoke;
      }

      th,
      td {
        border: 1px solid #ccc;
        padding: 10px;
        text-align: center;
      }

      .user-email,
      .createdAt {
        width: fit-content;
        padding-inline: 0.6rem;
        text-align: left;
      }

      th {
        background-color: #02205f;
        color: white;
        font-weight: bold;
      }

      td {
        font-family: sans-serif;
        color: #373a3c;
      }

      .remove-user {
        cursor: pointer;
        color: rgba(255, 0, 0, 0.47);

        .delete-icon {
          background: none;
        }

        .delete-icon:hover {
          color: red;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .Admin {
    .header {
      padding: 10px 20px;
      .nav-container {
        .logo-container {
          .img-box {
            .horizontal-logo {
              display: none;
            }
            .square-logo {
              display: inline-block;
              width: 50px;
            }
          }
        }
      }
    }

    .admin-login-section {
      width: 100%;
      //   padding-top: 50px;
      .main-heading {
        font-size: 1rem;
        padding-top: 20px;
      }
      .email-table {
        width: 100% !important;

        th,
        td {
          padding: 0;
        }
        th {
          font-weight: normal;
          padding-block: 2px;
          font-size: 0.7rem;
        }
        td {
          font-size: 0.6rem;
        }
      }
    }
  }
}
