* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: monospace, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  color: #373a3c;
  font-family: sans-serif;
}

h2,
h3,
h4,
h5,
p {
  font-family: sans-serif;
  color: #373a3c;
}


.btn-primary {
  width: calc(100% - 40px);
  background-color: #02205F;
  color: white;
  font-weight: bold;
  padding: 16px 40px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #00386C;
}

.btn-primary:active {
  background-color: #02205F;
}