.SentMessage {

    .container {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 1px 1px 5px grey;
        border-radius: 5px;
        margin-inline: auto;
        margin-top: 100px;

        .logo-box {
            margin-top: 1rem;
            color: green;
            font-size: 2rem;
        }

        h3 {
            margin-top: 1rem;
            color: green;
            letter-spacing: 1px;
        }

        p {
            padding-block: 1.5rem;
            color: #818198;
            font-size: 0.8rem;
        }

        .btn {
            width: fit-content;
            margin-bottom: 20px;
            padding: 0.5rem 40px;
        }
    }

}