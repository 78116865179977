.DownloadExcel{
    .btn-download{
        width: fit-content;
        padding: 6px 16px;
        border: none;
        background-color: #00A9FF;
        color: white;
        font-size: 0.5rem;
        border-radius: 2px;
        cursor: pointer;

        &:hover{
            background-color: #00aaffcd;
        }

        &:active{
            background-color: #00A9FF;
        }
    }
}